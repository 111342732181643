import { clientConfig } from "../clientConfig";
const platformClient = require("purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2.js");

const usersApi = new platformClient.UsersApi();

const client = platformClient.ApiClient.instance;
const { clientId, redirectUri } = clientConfig;
client.setEnvironment(process.env.REACT_APP_REGION);
const cache: any = {};

export function authenticate() {
    return client
      .loginImplicitGrant(clientId, redirectUri, { state: "state" })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

export async function getUserMe(skipCache: boolean = false) {
    if (skipCache) {
      return usersApi.getUsersMe({
        expand: ["routingStatus", "presence"],
      });
    } else if (cache["userMe"]) {
      return cache["userMe"];
    } else {
      try {
        cache["userMe"] = await usersApi.getUsersMe({
          expand: ["routingStatus", "presence"],
        });
        return cache["userMe"];
      } catch (err) {
        console.error(err);
      }
    }
  }

export async function getUserIds(){
const users:any={}
let pageNum = 1;
let bool =true;

while(bool){
  let opts = { 
    "pageSize": 100, // Number | Page size
    "pageNumber": pageNum, // Number | Page number
    "state": "active" // String | Only list users of this state
  };
  // Get the list of available users.
  await usersApi.getUsers(opts)
    .then((data:any) => {
      for(const user of data.entities){
        users[user?.id] = user?.name
      }
      data.nextUri!=null ? bool =true: bool=false;
      pageNum++
    })
    .catch((err:any) => {
      console.log("There was a failure calling getUsers");
      console.error(err);
      bool = false
    });
}
return users

}