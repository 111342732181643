import React, { useEffect, useState } from "react";
import "./App.css"
import {
  authenticate, 
  getUserMe,
  getUserIds
} from "./utils/genesysCloudUtils";

interface IUserDetails {
  id: string;
  name: string;
  images: IImage[];
  email: string;
  presence: {
    presenceDefinition: {
      systemPresence: string;
    };
  };
}
interface IImage {
  imageUri: string;
}

interface IAgent {
  name: string;
  score: number;
}

function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [scoreDictionary, setScoreDictionary] = useState<Record<string, Record<string, number>>>({});
  const [agentNames, setAgentNames]:any = useState({});
  const [queueNames, setQueueNames]:any = useState({});
  const [userName, setUserName] = useState('');
  const [userImage, setUserImage] = useState('');
  const [email, setEmail] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  useEffect(() => {
    
    const authenticate = async()=>{
      await getPlatformClientData();
      setAgentNames(await getUserIds())

      function socket(){
        try{
          const webSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}`);

      webSocket.onopen = () => {
        console.log('WebSocket Connected');
        setInterval(() => {
          if (webSocket.readyState === WebSocket.OPEN) {
            webSocket.send("Ping");
          }
        }, 30000);

      };
    
      webSocket.onmessage = (event) => {
        console.log(event)
        if(event.data!="Pong"){
          const data = JSON.parse(event.data)
          console.log('Data received from server:', data);
        agentNames == null ? data.AgentNames ?  setAgentNames(data?.AgentNames):null : null
        data.QueueNames ? setQueueNames(data.QueueNames):null;
        //setScoreDictionary(data?.ScoreDictionary);

        // Sort ScoreDictionary by QueueNames in alphabetical order
          if (data.ScoreDictionary && data.QueueNames) {
            // Create an array of sorted keys based on queue names
            const sortedQueueIds = Object.keys(data.ScoreDictionary).sort((a, b) => {
                const queueA = data.QueueNames[a] ? data.QueueNames[a].toLowerCase() : '';
                const queueB = data.QueueNames[b] ? data.QueueNames[b].toLowerCase() : '';
                return queueA.localeCompare(queueB);
            });

            // Create a new sorted ScoreDictionary
            const sortedScoreDictionary:any = {};
            sortedQueueIds.forEach(queueId => {
                sortedScoreDictionary[queueId] = data.ScoreDictionary[queueId];
            });

            // Set the sorted ScoreDictionary
            setScoreDictionary(sortedScoreDictionary);
            
          } else {
            // Fallback in case data.ScoreDictionary or data.QueueNames are missing
            setScoreDictionary(data?.ScoreDictionary);
          }

        }
      
        
      };
    
      webSocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        socket();
      };
    
      webSocket.onclose = (error) => {
        console.log(error)
        console.log('WebSocket Disconnected');
        
        socket();
      };
        }
        catch{
          socket();
        }
      }
      
    socket();
      // Clean up function to close WebSocket connection
      return () => {
        //webSocket.close();
      };

    }

    authenticate().catch(console.error);


  }, []);

  async function getPlatformClientData() {
    await authenticate()
      .then((data: any) => {
        return getUserMe();
      })
      .then((userDetailsResponse: IUserDetails) => {
        const presence =
          userDetailsResponse.presence?.presenceDefinition?.systemPresence ||
          "";
        const url =
          userDetailsResponse.images?.[userDetailsResponse.images?.length - 1]
            ?.imageUri || "";
        const userEmail = userDetailsResponse.email || "";
        const userId = userDetailsResponse.id || "";
        const name = userDetailsResponse.name || "";
        setUserName(name);
        setUserImage(url);
        setInitialized(true);
        setEmail(userEmail);
        // console.log(presence,url,userEmail,userId,name)
        // console.log(userDetailsResponse)
      })
      .catch((err: any) => {
        console.error(err);
      });
  }
  const handleScoreChange = async (queueId:string, agentId:string, index: number, amount: number) => {

    console.log(scoreDictionary[queueId][agentId])
    fetch(`${process.env.REACT_APP_API_URL}/v1/updateagentscore/${queueId}/${agentId}/${scoreDictionary[queueId][agentId]+amount}`,
    {method:"PUT"}
    )

  };
const Refresh=async()=>{
  setRefresh(true);
  setShowAlert(true)
  await fetch(`${process.env.REACT_APP_API_URL}/v1/refresh`)
  .then(async()=>{
    setRefresh(false)
    setShowAlert(true)
    
  })
  .catch(()=>{
    setRefresh(false)
  })
}

const AlertComponent = ({ message, onClose, refreshing }: any) => {
  useEffect(() => {
    if (!refreshing) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [onClose, refreshing]);
  console.log(message)
  return (
    <div className={`alert-modal ${refreshing ? 'refreshing' : ''}`}>
      <div className="alert-content">
        <p>{refreshing ? 'Refreshing...' : message}</p>
      </div>
    </div>
  );
};

const handleShowAlert = () => {
  setShowAlert(true);
};

const handleCloseAlert = () => {
  setShowAlert(false);
};

  return (
    <>
    {initialized ? 
    <div className="Container">
      {showAlert && (
  <AlertComponent
    message={refresh ? 'Refreshing...' : 'Queues Refreshed!'}
    onClose={handleCloseAlert}
    refreshing={refresh}
  />
)}
      <div className="user-info">
          <img src={userImage} alt={userName} className="user-image" />
          <div className="d-flex flex-column user-name">
          <span>Logged in as</span>
          <span>{userName}</span>
          </div>
          {/* <div className="dropdown">
            <button onClick={toggleDropdown} className="dropdown-button">▼</button>
            {showDropdown && (
              <div className="dropdown-content">
                <div className="card">
                  <p>Name: {userName}</p>
                  <p>Email: {email}</p>
                </div>
              </div>
            )}
          </div> */}
        </div>
      <div className="heading-container">
      <h1>Contact Center Score Tracker</h1>
      </div>
      <div className="text-start">
      <button className="btn btn-primary" disabled={refresh} onClick={Refresh}>Refresh Queues</button>
        {Object.entries(scoreDictionary).map(([queueId,agentScores], index)=>{
          return(
            <>
          <h4 className="mb-4">{queueNames[queueId]?queueNames[queueId]:queueId} :-</h4>
          <div className="main-card">
            {Object.entries(agentScores).map(([agentId,score],index)=>{
              return(
                <div className="agent-score" key={index}>
              <h5 className="pe-3">{agentNames[agentId]}</h5>
              <h5 className="pe-3">{score}</h5>
              <button className="btn btn-success me-3" disabled={score>=100} onClick={() => handleScoreChange(queueId,agentId,index, +1)}>+1</button>
              <button className="btn btn-success me-3" disabled={score<=0} onClick={() => handleScoreChange(queueId,agentId,index, -1)}>-1</button>
            </div>
              )
            })}
          </div>
          </>
          )
        })}
      </div>
      
    </div>
    : null}
    </>
  );
}

export default App;


